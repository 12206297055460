<template>
  <el-alert
    class="margin-bottom-20"
    :title="title"
    :description="description"
    :type="type"
    center
    show-icon
  />
</template>

<script>
export default {
  name: 'Banner',
  props: {
    type: {
      type: String,
      default: 'warning'
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  }
}
</script>
