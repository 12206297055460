<template>
  <div class="sales-wrapper page">
    <alert
      v-if="!getOpenedWorkingShift"
      type="error"
      title="Для создания продажи необходимо начать Рабочую смену!"
      description="Начать рабочую смену можно на страницах Рабочие смены или Главная"
    />

    <data-table
      :data="renderData"
      :hide-create="!getOpenedWorkingShift"
    >
      <template v-slot:buttonBlock>
        <label class="main-label">
          <span class="main-label__title">Кол. элементов в таблице</span>
          <el-select
            v-model="selectedDateRange"
            :class="getClass"
            placeholder="Выбирите"
            @change="selectDateRange"
          >
            <el-option
              v-for="item in listDateRange"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </label>

        <label class="main-label">
          <span class="main-label__title">Диапазон дат</span>

          <el-date-picker
            :value="dateRange"
            type="daterange"
            align="right"
            start-placeholder="Дата начала"
            end-placeholder="Дата окончания"
            :default-value="defaultDate"
            @input="selectDate"
          />
        </label>
      </template>
    </data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DataTable from '@/components/dataTable'
import Alert from '@/components/alert'
import moment from 'moment/moment'
import { getPriceToFormat, isDesktop } from '@/helper'

export default {
  name: 'Sales',
  data () {
    return {
      dateRange: [],
      defaultDate: moment().format(),
      selectedDateRange: 1,
      listDateRange: [
        {
          label: 'За сутки',
          value: 1
        },
        {
          label: 'За неделю',
          value: 7
        },
        {
          label: 'За месяц',
          value: 30
        },
        {
          label: 'За 3 месяца',
          value: 90
        }
      ]
    }
  },
  components: {
    DataTable,
    Alert
  },
  computed: {
    ...mapGetters('sales', { getDataSales: 'getData' }),
    ...mapGetters('products', { getDataProducts: 'getData' }),
    ...mapGetters('settings', ['getDefaultValues']),
    ...mapGetters('workingShift', ['getOpenedWorkingShift']),
    renderData () {
      return this.getDataSales.map(item => {
        return {
          ...item,
          typePay: this.getDefaultValues?.typePay?.filter(({ code }) => code === item.typePay)[0]?.name,
          renderPrice: `${getPriceToFormat(item.price)} р.`
        }
      })
    },
    getClass () {
      return isDesktop() ? 'sales-select__desktop' : 'sales-select__mobile'
    }
  },
  watch: {
    getOpenedWorkingShift: {
      handler (openedWorkingShift) {
        if (!openedWorkingShift?.openingAt) {
          return
        }

        // При первом открытии загружаем данные с дата открытия смены по дата открытия смены + 1 день
        this.dateRange = [
          moment(openedWorkingShift?.openingAt).format(),
          moment().add(1, 'day').format()
        ]
      },
      immediate: true
    },
    dateRange: {
      handler (newValue) {
        if (!newValue.length) {
          return
        }

        this.loadDataSales({
          date: [...newValue],
          force: true
        })
      },
      immediate: true
    }
  },
  async created () {
    await this.loadData()
  },
  methods: {
    ...mapActions('user', { loadDataUser: 'loadData' }),
    ...mapActions('settings', { loadDataSettings: 'loadData' }),
    ...mapActions('workingShift', { loadDataWorkingShift: 'loadData' }),
    ...mapActions('sales', { loadDataSales: 'loadData' }),
    ...mapActions('products', { loadDataProducts: 'loadData' }),
    ...mapActions('modal', ['openModal']),
    ...mapActions('workingShift', ['open', 'close']),
    async loadData () {
      // TODO: Подумать над оптимизацией загрузки данных НА ВСЕХ стр.
      await this.$bus.emit('start-loader')
      await this.loadDataUser()
      await this.loadDataSettings()
      await this.loadDataWorkingShift()
      await this.loadDataProducts()
      await this.$nextTick()
      await this.$bus.emit('stop-loader')
    },
    selectDateRange (value) {
      this.dateRange = [
        moment().subtract(value, 'days').format(),
        moment(this.getOpenedWorkingShift?.openingAt).format()
      ]
    },
    selectDate (values) {
      this.dateRange = [
        moment(values[0]).format(),
        moment(values[1]).format()
      ]
    }
  }
}
</script>

<style lang="scss" src="./style.scss" />